<template>
  <div  class="service_content" contenteditable="true"
        style="outline: none; min-height: 779px; background-color: white; padding: 20px 15%; border-radius: 4px 4px 0px 0px; margin-left: 5px; line-height: 32px; letter-spacing: 1px; margin-top: 0px; font-family: akkurat; margin-bottom: 0px;" tabindex="0" spellcheck="false"
        role="textbox" aria-multiline="true" aria-describedby="cke_42">
    <h2 style="text-align: center;font-size: 30px">
      <strong><strong>MetaGo产品服务协议</strong></strong>
    </h2>
    <p><strong></strong><strong><strong>导言</strong></strong></p>
    <p>欢迎您使用MetaGo产品（“<strong><strong>MetaGo</strong></strong>”）。</p>
    <p>
      《MetaGo产品服务协议》（“<strong><strong>本协议</strong></strong>”）系由用户（“<strong><strong>用户</strong></strong>”或“<strong><strong>您</strong></strong>”）与上海秘塔网络科技有限公司及其子公司（“<strong><strong>秘塔科技</strong></strong>”或“<strong><strong>我们</strong></strong>”）就用户使用MetaGo服务所订立的协议。
    </p>
    <p>
      <strong><u><strong><u>在接受本协议之前</u></strong></u></strong>，请您仔细阅读全部内容，如您无法准确理解或不同意本协议的任一内容，请不要进行后续操作。通过<strong><u><strong><u>登录</u></strong></u></strong>或者<strong><u><strong><u>使用</u></strong></u></strong>MetaGo服务，您承认您已仔细阅读和理解本协议的所有条款和条件以及平台所包含的其他指引、规则、政策（包括但不限于：《秘塔科技用户协议》《用户隐私政策》以及我们为提供某一具体服务所单独制定的用户协议、政策、规则等）（统称“条款”），并同意接受条款中的全部约定内容，受其约束。
    </p>
    <p>
      <strong><u><strong><u>请您特别注意仔细阅读服务条款中关于免除或者限制责任的条款、</u></strong></u></strong><strong><u><strong><u>违规/违约行为的认定和处理、</u></strong></u></strong><strong><u><strong><u>法律</u></strong></u></strong><strong><u><strong><u>适用</u></strong></u></strong><strong><u><strong><u>和争议解决条款等</u></strong></u></strong><strong><u><strong><u>，前述条款可能以加粗及</u></strong></u></strong><strong><u><strong><u>/或加下划线形式提示您注意</u></strong></u></strong><strong><strong>。</strong></strong>如果您不同意任何服务条款，则您有权停止访问MetaGo和使用相关服务。
    </p>
    <p><strong><strong>1. </strong></strong><strong><strong>MetaGo服务</strong></strong></p>
    <p>1.1.&nbsp;“MetaGo服务”指：秘塔科技向您提供metago.cn网站以及微信小程序（统称为“MetaGo网站”）上所展示的服务，主要包括文档自动化设置、编辑、分享等。您理解并认可，您在访问和使用MetaGo服务时能够实际享受的具体服务内容以您所使用或订购的内容为准。</p>
    <p>1.2.
      在遵守本协议约定的前提下，我们授予您一项不具排他性、有限、不可转让、不可分许可且可被撤销的许可用于访问和使用本平台，许可内容包括您对MetaGo网站的访问和使用。秘塔科技保留一切与MetaGo服务及秘塔科技内容相关的权利。您确认并同意：您在对本协议有任何违反时，秘塔科技可出于合理原因终止该许可，以避免您对秘塔科技及其他用户的进一步伤害。</p>
    <p>1.3.
      您应当从MetaGo网站或秘塔科技已经授权的第三方获取MetaGo服务，若您并非从秘塔科技或经秘塔科技授权的第三方获取MetaGo服务的，我们无法保证该版本的服务能够正常使用，您应自行为此种获取方式和使用行为负责。</p>
    <p>1.4.
      我们将不定期地对上述功能进行优化，并不断根据用户需求研发新的功能。您同意，这些功能服务可能根据用户需求的变化或我们的判断，随着服务版本不同而被优化或修改，或因定期、不定期地维护而暂缓提供。该等行为均不视为我们违反本协议约定。</p>
    <p>1.5.
      秘塔科技有权不时在不另行通知您的情况下，对本平台MetaGo网站、MetaGo服务或其任何部分及相关功能进行更新、升级、修改、删除或转移。我们会尽合理努力向所有用户发出合理的通知（例如通过MetaGo网站的通知），以向您告知MetaGo服务的相关变更，您应及时关注。</p>
    <p>1.6. 您在使用MetaGo网站所提供的相应功能时，应遵照秘塔科技不定时告知您的相关技术规范、安全要求等规定，以确保MetaGo服务安全、稳定地运行。</p>
    <p><strong><strong>2. 账号</strong></strong></p>
    <p>2.1. MetaGo为您提供了注册通道，您有权选择合法的字符组合作为自己的账号，并自行设置符合安全要求的密码。用户设置的账号、密码是用户用以登录MetaGo客户端并使用服务的凭证。</p>
    <p>2.2.
      您理解并承诺，您所设置的账号不得违反国家法律法规及平台的相关规则，不得实施任何侵害国家利益、损害其他公民合法权益，有害社会道德风尚的行为，您的账号名称、头像和简介等注册信息及其他个人信息中不得出现违法和不良信息，未经他人许可不得用他人名义（包括但不限于冒用他人姓名、名称、字号、头像等足以让人引起混淆的方式）开设账号，不得恶意注册账号（包括但不限于频繁注册、批量注册账号等行为）。我们有权对您提交的信息进行审核。</p>
    <p>2.3.
      您的账号仅限于您本人使用，未经秘塔科技书面同意，禁止以任何形式赠与、借用、出租、转让、售卖或以其他方式许可他人使用该账号。如果秘塔科技发现或者有合理理由认为使用者并非账号初始注册人，为保障账号安全，秘塔科技有权立即暂停或终止向该注册账号提供服务，或注销该账号。</p>
    <p>2.4. 您有责任维护个人账号、密码的安全性与保密性，并对您以注册账号名义所从事的活动承担全部法律责任。</p>
    <p>2.5. 如果您认为您的密码或者访问您账号的其他方式已经泄露，丢失或被盗，或者认为某一未授权个人正在或可能试图通过您的账号登录平台或使用服务，请务必立即发送电子邮件至<strong><strong>Support@meatasota.ai</strong></strong>或致电秘塔科技。
    </p>
    <p><strong><strong>3. 您的内容</strong></strong></p>
    <p>3.1. MetaGo服务允许用户上传、存储或共享自己的信息内容，或者接收其他用户发送的材料。</p>
    <p>3.2.
      用户在MetaGo上传、存储或共享的信息内容仅表明其个人的立场和观点，并不代表秘塔科技的立场或观点。<strong><u><strong><u>我们不会对用户的</u></strong></u></strong><strong><u><strong><u>信息</u></strong></u></strong><strong><u><strong><u>内容主张所有权，用户自行对其</u></strong></u></strong><strong><u><strong><u>信息</u></strong></u></strong><strong><u><strong><u>内容负责</u></strong></u></strong>。
    </p>
    <p>3.3.
      用户声明并保证，在本协议的有效期内，用户拥有（并将一直拥有）用户上传、存储到服务或通过服务共享的用户的内容的所有必要权利，并且秘塔科技收集、使用和保留您的内容不会违反任何法律和他人的权利。秘塔科技并不拥有、控制、验证、支付、认可用户的内容，或者以其他方式对用户的内容承担任何责任。因用户上传、存储或共享的信息内容引发的一切纠纷，由相关用户承担全部法律责任，秘塔科技不承担任何法律责任。</p>
    <p>3.4.
      您理解并承诺，在为您或他人提供服务、保护您和服务的安全以及改进MetaGo服务所必需的范围内，您在全球范围内授予秘塔科技免费的非独占的知识产权许可，允许秘塔科技使用您的内容，例如，在MetaGo服务中复制、保留、传输、重新设置格式、显示和通过通信工具分发您的内容。</p>
    <p><strong><strong>4. 授权使用及限制</strong></strong></p>
    <p>4.1.
      MetaGo内容。MetaGo及其客户端所包含和提供的内容（包括但不限于源代码、软件、技术、程序、网页、界面设计、版面框架、文字、图片、图像、音频、视频、图表电子文档以及任何由秘塔科技根据维护与技术支持提供的软件、资料等）（“<strong><strong>平台内容</strong></strong>”）的知识产权属于秘塔科技所有。您认同秘塔科技拥有的所有权及知识产权，并承诺尊重及不以任何方式侵害秘塔科技的所有权及知识产权。未经秘塔科技书面许可，您不得复制、传播、转让、许可或提供他人使用上述知识成果，否则应承担相应的责任，且秘塔科技有权终止您对MetaGo及其他秘塔科技旗下服务的使用且不退还任何费用。
    </p>
    <p>4.2. 商标。平台所使用和显示的商标、服务标识、商品名称和标志（统称为“<strong><strong>商业标识</strong></strong>”）归秘塔科技或另行指定的第三方所有。您不得以任何目的复制、仿造或使用这些商业标识或与这些商业标识整体或部分相似的任何标志。您未被许可或授权使用平台使用或显示的任何商业标识。
    </p>
    <p>4.3.
      软件许可。任何被我们当作服务一部分为您提供的软件均受这些条款的约束。如果您遵守本协议，我方授予您在使用服务期间在全球范围内每台设备仅由一个人安装和使用该软件的一个副本的权利。作为服务组成部分的软件或网站可能包括第三方代码。任何第三方脚本或代码（链接至软件或网站或引用自软件或网站）是由拥有此类代码的第三方（而非秘塔科技）向您授予相关许可。该软件并未出售，只是授予许可，我们保留未明确授予的对该软件的所有权利（无论是以默示、禁止否认的方式还是以其他方式）。</p>
    <p><strong><strong>5. 付费项目</strong></strong></p>
    <p>
      您使用MetaGo的收费服务时，应当按照秘塔科技的要求支付相应的费用。而且，该等权利属于秘塔科技的经营自主权，秘塔科技保留随时更改经营模式的权利，即保留变更收费的费率标准、收费的软件功能、收费对象、收费时间以及对于预付款的管理等权利。</p>
    <p><strong><strong>6. 用户行为规范</strong></strong></p>
    <p>您应当对您使用MetaGo服务的行为负责，除非法律允许或者经秘塔科技事先书面许可，您使用MetaGo服务<strong><u><strong><u>不得具有下列行为</u></strong></u></strong>：</p>
    <p>6.1.以任何理由访问或企图访问秘塔科技的计算机系统区域或其他系统信息（除非根据“订阅协议”，您已经明确被允许访问与平台有关的特定部分）。</p>
    <p>6.2.以任何目的使用网络爬虫或其他自动下载程序或设备，从事包括但不限于从平台获取他人邮寄或电子邮件地址而发送任何未经请求或授权的商业广告的行为。</p>
    <p>6.3.使用未经秘塔科技授权或许可的任何插件、外挂、系统或第三方工具对平台及相关服务的正常运行进行干扰、破坏、修改或施加其他影响。</p>
    <p>6.4.利用或针对MetaGo服务进行任何危害计算机网络安全的行为，包括但不限于：</p>
    <ol>
      <li>非法侵入他人网络、干扰他人网络正常功能、窃取网络数据等危害网络 安全的活动；</li>
      <li>提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络 数据等危害网络安全活动的程序、工具；</li>
      <li>明知他人从事危害网络安全的活动的，为其提供技术支持、广告推广、 支付结算等帮助；</li>
      <li>使用未经许可的数据或进入未经许可的服务器/账号；</li>
      <li>未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加 存储信息；</li>
      <li>未经许可，企图探查、扫描、测试系统或网络的弱点或其它实施破坏网 络安全的行为；</li>
      <li>企图干涉、破坏平台的正常运行，故意传播恶意程序或病毒以及其他破 坏干扰正常网络信息服务的行为；</li>
      <li>伪造TCP/IP数据包名称或部分名称；</li>
      <li>对平台及服务进行反向工程、反向汇编、编译或者以其他方式尝试发现本软件的源代码；</li>
      <li>恶意注册MetaGo的账号，包括但不限于频繁、批量注册账号；</li>
      <li>违反法律法规、本协议、公司的相关规则及侵犯他人合法权益的其他行为。</li>
    </ol>
    <p>6.5.
      使用MetaGo上传、分享任何违反相关法律法规的，或侵犯他人权利的，或具有骚扰性、中伤他人、辱骂、恐吓、伤害、憎恶、庸俗、淫秽、亵渎、色情、恐吓、粗俗、危害国家安全、泄露国家机密、破坏国家宗教政策和民族团结性质的，包含错误、病毒或其他危害性成分的信息。</p>
    <p>6.6. 上传或分享敏感信息和违反国家法律制度的信息，包括但不限于下列信息：</p>
    <ol>
      <li>反对宪法所确定的基本原则的；</li>
      <li>危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</li>
      <li>损害国家荣誉和利益的；</li>
      <li>煽动民族仇恨、民族歧视，破坏民族团结的；</li>
      <li>破坏国家宗教政策，宣扬邪教和封建迷信的；</li>
      <li>散布谣言，扰乱社会秩序，破坏社会稳定的；</li>
      <li>散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</li>
      <li>侮辱或者诽谤他人，侵害他人合法权益的；</li>
      <li>含有法律、行政法规禁止的其他内容的。</li>
    </ol>
    <p>
      <strong><u><strong><u>如果我们有理由认为您的行为违反或可能违反上述约定的，我们可独立进行判断并处理，且有权在不事先通知的情况下终止向您提供服务，并追究相关法律责任。</u></strong></u></strong>
    </p>
    <p><strong><strong>7. 免责声明</strong></strong></p>
    <p>7.1. 秘塔科技在法律所允许的最大范围内明确否认所有明示或隐含的保证和陈述，包括但不限于：(1)保证符合特定目的；及(2)保证平台的任何信息材料或您使用服务绝无错误、永不中断、不受其它故障影响或会达到您的要求。</p>
    <p>7.2. <strong><u><strong><u>您知悉并同意，下列情况下，</u></strong></u></strong><strong><u><strong><u>秘塔科技将不承担以下责任</u></strong></u></strong>：
    </p>
    <ol>
      <li>由于您维护不当或保密不当，导致数据、资料、口令、密码等丢失或泄漏，或其他非因秘塔科技原因导致的个人信息的泄漏，秘塔科技不承担任何法律责任；</li>
      <li>任何由于计算机病毒、或其他恶意软件、程序、代码等以任何方式对您的计算机系统造成的任何负面影响，或造成您的系统崩溃或出现其他技术问题，秘塔科技不承担任何法律责任；</li>
      <li>
        任何由于不可抗力、黑客攻击、电脑病毒侵入、网络运营商原因或政府管制等秘塔科技无法合理控制的原因而造成MetaGo服务使用中止、中断、延误、使用限制、使用异常、使用不能、暂时性网站关闭等，秘塔科技不承担任何法律责任；
      </li>
      <li>在提供或访问平台过程中使用任何第三方服务，该第三方的任何行为、疏忽或过失，秘塔科技不承担任何法律责任；</li>
      <li>您应对通过MetaGo服务上传文件、输入数据、发表内容的合法性以及获得文件、数据、内容的方式的合法性负责，因上述文件、数据、内容引发的一切纠纷，由您承担全部法律责任，秘塔科技不承担任何法律责任；</li>
      <li>
        互联网是一个开放平台，如果您将图片、文字等内容上传并分享，有可能会被其他组织或个人复制、转载、删改或做其它非法用途，您应充分意识到此类风险的存在。您同意使用秘塔科技服务所存在的此类风险及后果将完全由您自己承担，秘塔科技不承担任何责任；
      </li>
      <li>
        您理解并确认，受技术所限，秘塔科技无法保证其所提供的服务毫无瑕疵，但我们承诺将不断提升服务质量及服务水平，为您提供更加优质的服务。据此，您同意：如果秘塔科技所提供的服务虽然存在瑕疵，但该等瑕疵是当时行业技术水平所无法避免的，其将不被视为违约，秘塔科技无须因此向您承担任何责任。
      </li>
    </ol>
    <p>7.3.
      在任何情况下，对于使用或未能使用MetaGo或MetaGo内容而导致的间接性的、特殊的、附带的、惩罚性的、后果性的损失，或者由此造成的利润损失、数据丢失或业务中断等损失，无论以何种形式提起诉讼，即无论基于合同法、侵权法（包括但不限于过失）还是其他任何法律理论，亦无论是否已被告知该等损失的可能性，<strong><u><strong><u>秘塔科技或任何明示的第三方服务提供者或其董事、职员、雇员、分包商、代理商、继承人、受让人、关联方或下属机构，均不承担责任</u></strong></u></strong>。
    </p>
    <p><strong><strong>8.广告</strong></strong></p>
    <p>8.1.您理解并同意，在使用服务过程中我们可能会向您展示秘塔科技、其关联方以及第三方产品和服务的广告和推广信息。此类广告和推广信息的内容可能会以您使用服务中所展示的偏好、兴趣为基础向您推送。</p>
    <p>
      8.2.我们依照法律规定履行广告及推广相关义务，您应当自行判断该广告或推广信息的真实性和可靠性并为自己的判断行为负责。除法律法规明确规定外，您因该广告或推广信息进行的购买、交易或因前述内容遭受的损害或损失由您自行承担，秘塔科技不予承担责任。</p>
    <p>8.3.平台广告的方式、模式和范围如有变更，恕不另行通知。</p>
    <p><strong><strong>9.违约处理</strong></strong></p>
    <p>9.1. <strong><u><strong><u>针对您违反本协议或其他服务条款的行为，我们有权独立判断并视情况</u></strong></u></strong><strong><u><strong><u>在不经事先通知您的情况下采取合理的处置措施</u></strong></u></strong>，包括但不限于限制、暂停、终止您使用MetaGo服务的部分或全部功能，屏蔽或删除您上传、分享的内容、限制账号部分或者全部功能直至永久关闭账号等措施，<strong><u><strong><u>您需自行承担由此导致的后果及损失</u></strong></u></strong>。我们有权公告处理结果，且有权根据实际情况决定是否恢复相关账号的使用。对涉嫌违反法律法规、涉嫌违法犯罪的行为将保存有关记录，并依法向有关主管部门报告、配合有关主管部门调查。
    </p>
    <p>9.2.&nbsp;<strong><u><strong><u>如果您在使用MetaGo服务过程中违反本协议和</u></strong></u></strong><strong><u><strong><u>/或其他您应遵守的其他条款约定的情况</u></strong></u></strong>，在适用法律允许的情况下，您同意根据本协议或相关法律法规的规定，向秘塔科技，及其拥有人、运营商、关联方以及其各自的董事、职员、许可方及被许可方、继承人或受让人作出足额赔偿，赔偿范围包括但不限于：因您的行为导致秘塔科技及上述相关方向任何第三方支付的赔偿、遭受的国家机关罚款等。未经秘塔科技事先书面同意，不得擅自解决任何索赔，除非这些解决方案完全免除了秘塔科技的所有责任，并且不包括或要求秘塔科技作出任何支付或救济。
    </p>
    <p>9.3.
      我们尊重并保护用户及他人的知识产权、名誉权、姓名权、隐私权等合法权益。您保证，在使用服务时上传的文字、图片、视频、音频、链接等不侵犯任何第三方的知识产权、名誉权、姓名权、隐私权等权利及合法权益。否则，我们有权在收到权利方或者相关方通知的情况下移除该涉嫌侵权内容。<strong><u><strong><u>针对第三方提出的全部权利主张，您应自行处理并承担全部可能由此引起的法律责任；如因您的侵权行为导致公司及其关联公司遭受损失的（包括经济、商誉等损失），您还应足额赔偿我们及其关联公司遭受的全部损失。</u></strong></u></strong>
    </p>
    <p><strong><strong>10.法律适用和管辖</strong></strong></p>
    <p>10.1.本协议的成立、生效、履行、解释及争议的解决均应适用<strong><u><strong><u>中华人民共和国法律</u></strong></u></strong>。若本协议之任何规定因与中华人民共和国的法律抵触而无效或不可执行，则这些条款将尽可能按照接近本协议原条文意旨重新解析，且本协议其它规定仍应具有完整的效力及效果。
    </p>
    <p>
      10.2.您因本协议与秘塔科技发生任何争议，双方应尽量友好协商解决；如协商不成的，您同意应将相关争议提交至<strong><u><strong><u>有管辖权的人民法院诉讼解决</u></strong></u></strong>。
    </p>
    <p><strong><strong>11.本协议的更新</strong></strong></p>
    <p>
      我们会适时对本协议进行修订，这些修订构成本协议的一部分。本协议更新后，我们会在MetaGo相关平台发出更新版本，并在更新后的条款生效前通过平台公告或其他适当的方式提醒您更新的内容，以便您及时了解本协议的最新版本，你也可以在MetaGo相关平台首页查阅最新版本的协议条款。如您继续使用服务，表示同意接受修订后的本协议的内容。</p>
  </div>
</template>

<script>
export default {
  name: "servicePolicy",
  created() {
    document.title = 'MetaGo产品服务协议';
  }
}
</script>

<style scoped>
.service_content{
  text-align: left;
  font-family: Avenir,Helvetica,Arial,sans-serif;
  color: #2c3e50
}
</style>
